/* Your CSS styling rules go here */

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

.chatbot_container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}
.chatbot_iframe {
  width: 410px;
  height: 700px;
}
.licenseContainer {
  width: 100%;
  padding: 8px 0;
  border-top: 1px solid #eee;
}

.licenseContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.licenseImg {
  height: 14px; /* Adjust based on your image dimensions */
  width: auto;
}

.licenseText {
  font-size: 12px;
  color: #666;
}
.floating_chat {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 150px;
  overflow: hidden;
  height: 50px;
  transform: translateY(70px);
  transition: all 250ms ease-out;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  background: #ffffff;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border: black;
}
.title_price {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 5px;
}
.li_class {
  padding-left: 0px;
  width: 15rem;
}
.box_card a {
  display: flex;
  text-decoration: none;
  height: 3rem;
}
.box_card {
  display: flex;
  padding-bottom: 26px;
  margin-top: 5px;
  overflow: hidden;
  width: 90%;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid red;
}
.box_card img {
  height: 2.9rem;
  width: 2.9rem;
  border-radius: 5px;
}

.title_price h4 {
  font-size: 0.6rem;
  margin-block: 0px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  color: #c40000;
}
.title_price p {
  font-size: 0.6rem;
  margin-block: 0px;
  color: #c40000;
}
.floating_chat:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.floating_chat {
  transform: translateY(0);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
}
.floating_chat_expand {
  min-width: 300px;
  width: 300px; /* 30% of the viewport width minus 15px */
  height: 500px; /* 80% of the viewport height minus 15px */
  max-width: 500px; /* Set a maximum width */
  max-height: 600px; /* Set a maximum height */
  border-radius: 10px;
  cursor: auto;
  opacity: 1;
}
.floating_chat :focus {
  outline: 0;
}
.floating_chat_btn {
  background: transparent;
  border: 0;
  /* color: white; */
  /* text-transform: uppercase; */
  border-radius: 3px;
  cursor: pointer;
}

/* issue starts here */
.chat {
  display: flex;
  flex-direction: column;
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  /* border-radius: 50%; */
  transition: all 250ms ease-out;
  /* margin: auto; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.chat {
  opacity: 1;
  border-radius: 0;
  /* margin: 10px; */
  width: auto;
  height: auto;
}
.header {
  flex-shrink: 0;
  z-index: 5;

  align-items: center;
  display: flex;
  background: white;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  border-radius: 10px 10px 0 0;
  color: #ffffff;
}
.title {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 5px;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font: bolder;
  color: #e14343;
}

.button-container {
  display: flex;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.minimizeButton,
.closeButton {
  cursor: pointer;
}

.minimizeButton {
  color: #ff4d4d;
  font-size: 1.2rem;
}

.minimizeButton:hover {
  color: #e14343;
}

.minimizeButton:active {
  transform: scale(0.9);
}

.closeButton {
  color: #ff4d4d;
  font-size: 1.4rem;
}

.closeButton:hover {
  color: #e14343;
}

.closeButton:active {
  transform: scale(0.9);
}

.floating_chat_header_btn {
  flex-shrink: 0;
}
.messages {
  padding: 10px;
  margin: 0;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  border-radius: 4px;
  background: transparent;
}
.messages::-webkit-scrollbar {
  width: 5px;
}
.messages::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(134, 40, 0, 0.1);
}
.messages::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(235, 168, 168, 0.2);
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: calc(100% - 40px);
  height: calc(100% - 40px);

  padding: 20px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;

  word-wrap: break-word;

  position: relative;
  border-radius: 8px; /* Add rounded corners */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Add a shadow */
}

.modal-text {
  font-size: 18px; /* Increase the font size */
  color: #333; /* Set the text color */
  margin-bottom: 20px; /* Add some space below the text */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal-buttons button {
  background-color: #e14343; /* Set the background color */
  color: white; /* Set the text color */
  padding: 10px 20px; /* Add some padding */
  border: none; /* Remove the border */
  border-radius: 4px; /* Add rounded corners */
  cursor: pointer; /* Change the cursor to a pointer */
  margin-right: 10px; /* Add some space between the buttons */
}

.modal-buttons button:hover {
  background-color: #45a049; /* Change the background color on hover */
}
.gifts_self {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 14px;
  margin: 0 0 20px 0;
  font: 12px "Noto Sans", sans-serif;
  /* border-radius: 10px; */
  background-color: white;
  word-wrap: break-word;
  max-width: 81%;
  font-size: 12px;
  border-radius: 0.9em;
  border: 0.1em solid #e14343;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.gifts_last_self {
  position: relative;
  clear: both;
  display: inline-block;
  padding-left: 3px;
  padding-top: 14px;
  padding-bottom: 14px;
  margin: 10px 0 10px 0;
  font: 12px "Noto Sans", sans-serif;
  /* border-radius: 10px; */
  background-color: white;
  word-wrap: break-word;
  max-width: 100%;
  font-size: 12px;
  border-radius: 0.9em;
  border: 0.1em solid #e14343;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.self {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 14px;
  margin: 0 0 20px 0;

  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  word-wrap: break-word;
  max-width: 81%;
  font-size: 13px;
  border-radius: 2em;
  border: 2em solid #e14343;
}
.self:before {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  content: "";
  background-size: cover;
}
.self:after {
  position: absolute;
  top: 10px;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid rgba(25, 147, 147, 0.2);
}
.other {
  animation: bot_show-chat-odd__H_7wf 0.15s 1 ease-in;
  -moz-animation: bot_show-chat-odd__H_7wf 0.15s 1 ease-in;
  -webkit-animation: bot_show-chat-odd__H_7wf 0.15s 1 ease-in;
  float: right;
  background-color: #e14343;
  color: white;
  border-radius: 20px 20px 0 20px;
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  word-wrap: break-word;
  max-width: 81%;
  font-size: 13px;
  position: relative;
  clear: both;
  display: inline-block;
  padding: 10px 15px;
  margin: 0 0 20px 0;
}

.other:before {
  content: "\2764";
  position: absolute;
  bottom: 50%;
  left: -22px;
  transform: translateY(50%);
  font-size: 12px;
  color: red;
}
.self {
  animation: show-chat-even 0.15s 1 ease-in;
  -moz-animation: show-chat-even 0.15s 1 ease-in;
  -webkit-animation: show-chat-even 0.15s 1 ease-in;
  float: left;
  /* margin-left: 45px; */
  /* color: #0EC879; */
  border: 0.1em solid;
  border-color: #e14343;
  border-radius: 0px 20px 20px 20px;
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  padding: 10px 15px;

  background-color: white;
  max-width: 81%;
}

.self::after {
  content: "\2764"; /* Unicode for heart emoji */
  position: absolute;
  bottom: 50%;
  right: -15px;
  transform: translateY(50%);
  font-size: 16px;
  color: red;
}
.footer {
  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-top: 1px solid #e14343;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.footerInside {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}
.footerInput {
  flex: 1;
  border: none;
  outline: none;
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
}

.sendButton {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.25rem;
  color: #e14343;
  margin-left: 0.5rem;
}
.text_box {
  border-radius: 3px;
  background: white;
  min-height: 100%;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
  /* color: #0EC879; */
  overflow-y: auto;
  /* padding: 2px 5px; */
  padding: 3px;
  border: 0.05em solid;
  border-color: #e14343;
}

.text-box::-webkit-scrollbar {
  width: 5px;
}
.text-box::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(25, 147, 147, 0.1);
}
.text-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(235, 168, 168, 0.2);
}

@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}

.fa_comments {
  content: url("./images/icons/Need-Help-G.png");
  border-radius: 25px;
  position: absolute;
}

.chat {
  width: auto;
  height: auto;
}

.virtuos_heart {
  margin-top: auto;
  height: 2rem;
  width: 2rem;
}
.fa_microphone {
  font-size: 24px;
  color: #d41515;
  margin: 3px;
}

.fa_paper_plane {
  font-size: 24px;
  color: #d41515;
  margin: 3px;
}

.minimize_icon {
  color: #e14343;
}

.row {
  display: -ms-flexbox; /* IE 10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE 10 */
  flex-wrap: wrap;
  margin-top: 5px;
  /* padding: 0 4px; */
}

.column {
  /* IE 10 */
  -ms-flex: 50%;
  flex: 50%;
  margin-top: 5px;
  /* padding: 0 4px 0 0; */
  /* font-weight: bold; */
}
.column_new {
  /* IE 10 */
  -ms-flex: 33.3%;
  flex: 33.3%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  /* padding: 0 4px 0 0; */
  /* font-weight: bold; */
}
.customText {
}

.li_button {
  /* background: rgb(39, 0, 182); */
  text-decoration: none;
  background-color: #e14343;
  border-radius: 0.9em;
  /* border: 1px solid black; */
  color: white;
  padding: 3px;
  width: -webkit-fill-available;
  text-align: center;
  display: inline-block;
  /* font-size: 20px;  */
  margin: 8px 4px;
  border: none;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.li_button_rounded {
  /* background: rgb(39, 0, 182); */
  text-decoration: none;
  background-color: #e14343;
  border-radius: 100px;
  /* border: 1px solid black; */
  color: white;
  padding: 3px;

  width: 35px;
  height: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 20px;  */
  margin: 8px 4px;
  border: none;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.li_button_rounded:hover {
  background-color: #d73737;
  transform: scale(1.1);
}
#li-button {
  /* background: rgb(39, 0, 182); */
  text-decoration: none;
  background-color: #e14343;
  border-radius: 0.9em;
  /* border: 1px solid black; */
  color: white;
  padding: 3px;
  width: -webkit-fill-available;
  text-align: center;
  display: inline-block;
  /* font-size: 20px;  */
  margin: 8px 4px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.roundedInput {
  border-radius: 10px;
  padding: 8px; /* Adding some padding for better appearance */
  border: 1px solid #c40000; /* Adding a border for clarity */
  margin-bottom: 10px;
}

/* Style for the required asterisk */
.required {
  color: red; /* Change the color of the asterisk to red */
  margin-left: 5px; /* Add a bit of space between the label text and the asterisk */
  /* make this take the entire remaining space */
  flex-grow: 1;
}

.required::before {
  content: "*"; /* Display the asterisk */
}

.product_link {
  color: #c40000; /* Change the text color here */
  text-decoration: underline; /* Optional: Add underline to the link */
}

.see_more {
  color: #c40000; /* Change the text color here */
  text-decoration: underline; /* Optional: Add underline to the link */
  position: relative;
  clear: both;
  display: inline-block;
  padding: 14px;
  margin: 0 0 20px 0;
  font: 12px "Noto Sans", sans-serif;
  /* border-radius: 10px; */
  background-color: white;
  word-wrap: break-word;
  max-width: 81%;
  font-size: 12px;
  border-radius: 0.9em;
  border: 0.1em solid #e14343;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.response_redirect {
  text-decoration: none;
}

.hidden {
  display: none;
}

/*--------------------
Custom Srollbar
--------------------*/
/* .mCSB_scrollTools {
  margin: 1px -3px 1px 0;
  opacity: 0;
}

.mCSB_inside > .mCSB_container {
  margin-right: 0px;
  padding: 0 10px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.5) !important;
} */
/*# sourceMappingURL=style.css.map */

/* Star Ratings CSS */
.rating {
  position: relative;
  clear: both;
  padding: 14px;
  display: flex;
  margin: -6px 0 12px 0;
  /* width: 193px; */
  height: 26px;
  justify-content: center;
  flex-direction: row-reverse; /* Reverses the order of items */
}

.rating.hidden {
  display: none;
}

.star_label {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.5rem;
}

.star_label:not(:first-of-type) {
  padding-left: 2px;
}

.star_label:before {
  /* content: "1F611"; */
  font-size: 24px;
  filter: grayscale();
  line-height: 1;
}

.star1:before {
  content: "\1F611"; /* Sad emoji */
}

.star2:before {
  content: "\1F641"; /* Slightly sad emoji */
}

.star3:before {
  content: "\1F610"; /* Neutral emoji */
}

.star4:before {
  content: "\1F642"; /* Slightly happy emoji */
}

.star5:before {
  content: "\1F603"; /* Happy emoji */
}

.rating input {
  display: none;
}

.rating input {
  display: none;
}

.rating input:checked ~ label:before,
.rating:not(:checked) > label:hover:before,
.rating:not(:checked) > label:hover ~ label:before {
  filter: none;
}

/* CSS styles for the "thinking" indicator */
.thinking_indicator {
  font-size: 16px; /* Customize the font size */
  font-weight: bold; /* Make the text bold */
  opacity: 0.7; /* Set the opacity to 0.7 */
  display: none; /* Hide it by default */
}

/* pop up message on stars rating */
#thankYouMessage {
  display: none;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 10px;
  z-index: 1000;
  opacity: 1; /* Initially hidden */
  transition: opacity 0.5s ease-in-out; /* Smooth fade in/out */
  white-space: nowrap; /* Prevent text from wrapping */
}

/* .whatsapp-container
{
display: flex;
flex-direction: row;
}

.whatsapp_msg
{
  width: 77%;
    text-align: justify;
} */
/* 
.whatsapp-logo
{
  font-size: 14px;
  width: 14px;
  height: 14px;
  margin-top: auto;
} */

.whatsapp_msg img {
  font-size: 14px;
  width: 14px;
  height: 14px;
  margin-top: auto;
  vertical-align: bottom;
}
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 0.8rem;
  color: #000 !important;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 0.8rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.6rem;
  color: #9b9b9b;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #e14343, #e14343);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.6rem;
  color: #e14343;
  font-weight: 700;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}
