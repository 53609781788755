
body {
  margin: 0;
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
   
}


*{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
}
